import {useLocalizationService} from '@/composables/localization_service'
import {expandTranslations} from '~/server/translations/translations_methods.js'

export default defineI18nLocale(async () => {
  const {getTranslationsForLocales, getTextTranslationsForLocales} = useLocalizationService()

  const translations = (await getTranslationsForLocales('es')).reduce((o: { [x: string]: any }, v: { Entity: string; Key: string; Value: any }) => { const key = (v.Entity ? (v.Entity + '.') : '') + v.Key; o[key] = v.Value; return o }, {})
  const textTranslations = (await getTextTranslationsForLocales('es')).reduce((o: { [x: string]: any; }, v: { Key: string; Translation: any; }) => { const key = v.Key; o[key] = v.Translation; return o }, {})
  return expandTranslations(translations, textTranslations)
})
